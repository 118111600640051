// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfapiintegrationcloverlyvehicle from "../../blocks/cfapiintegrationcloverlyvehicle/src/Cfapiintegrationcloverlyvehicle";
import Cfapiintegrationcloverlyhotel from "../../blocks/cfapiintegrationcloverlyhotel/src/Cfapiintegrationcloverlyhotel";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Hamburgermenu from "../../blocks/hamburgermenu/src/Hamburgermenu";
import Surveys2 from "../../blocks/surveys2/src/Surveys2";
import Cfapiintegrationtrycarbonfueltoco2e2 from "../../blocks/cfapiintegrationtrycarbonfueltoco2e2/src/Cfapiintegrationtrycarbonfueltoco2e2";
import Cfapiintegrationcloverlybycarbonamount from "../../blocks/cfapiintegrationcloverlybycarbonamount/src/Cfapiintegrationcloverlybycarbonamount";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Cfapiintegrationtrycarboncleanenergy2 from "../../blocks/cfapiintegrationtrycarboncleanenergy2/src/Cfapiintegrationtrycarboncleanenergy2";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import Dynamiccontent2 from "../../blocks/dynamiccontent2/src/Dynamiccontent2";
import Fullteamvisibility2 from "../../blocks/fullteamvisibility2/src/Fullteamvisibility2";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Interactivecalculator2 from "../../blocks/interactivecalculator2/src/Interactivecalculator2";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Cfapiintegrationcloverlypackageshipping from "../../blocks/cfapiintegrationcloverlypackageshipping/src/Cfapiintegrationcloverlypackageshipping";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Productrecommendationengine from "../../blocks/productrecommendationengine/src/Productrecommendationengine";
import Cfapiintegrationgivvablegetorganisations from "../../blocks/cfapiintegrationgivvablegetorganisations/src/Cfapiintegrationgivvablegetorganisations";
import Download from "../../blocks/download/src/Download";
import Merchantdashboard2 from "../../blocks/merchantdashboard2/src/Merchantdashboard2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Cfapiintegrationtrycarbonpublictransit from "../../blocks/cfapiintegrationtrycarbonpublictransit/src/Cfapiintegrationtrycarbonpublictransit";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Blogpostsmanagement from "../../blocks/blogpostsmanagement/src/Blogpostsmanagement";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfapiintegrationgivvablegetcategories from "../../blocks/cfapiintegrationgivvablegetcategories/src/Cfapiintegrationgivvablegetcategories";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Cfapiintegrationcloverlyfreight from "../../blocks/cfapiintegrationcloverlyfreight/src/Cfapiintegrationcloverlyfreight";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Cfapiintegrationgivvablegetevents2 from "../../blocks/cfapiintegrationgivvablegetevents2/src/Cfapiintegrationgivvablegetevents2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Cfblogapiintegration2 from "../../blocks/cfblogapiintegration2/src/Cfblogapiintegration2";
import Chatbot from "../../blocks/chatbot/src/Chatbot";
import Projectreporting from "../../blocks/projectreporting/src/Projectreporting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Cfapiintegrationtrycarbonflight from "../../blocks/cfapiintegrationtrycarbonflight/src/Cfapiintegrationtrycarbonflight";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Goalmanagement from "../../blocks/goalmanagement/src/Goalmanagement";
import Cfapiintegrationtrycarboncartravel2 from "../../blocks/cfapiintegrationtrycarboncartravel2/src/Cfapiintegrationtrycarboncartravel2";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import Cfapiintegrationcloverlyflight from "../../blocks/cfapiintegrationcloverlyflight/src/Cfapiintegrationcloverlyflight";
import Cfesgmaterialitymatrixmanagement3 from "../../blocks/cfesgmaterialitymatrixmanagement3/src/Cfesgmaterialitymatrixmanagement3";
import Cfapiintegrationgivvablegetidentifiertypebyid from "../../blocks/cfapiintegrationgivvablegetidentifiertypebyid/src/Cfapiintegrationgivvablegetidentifiertypebyid";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Cfapiintegrationcloverlyelectricity from "../../blocks/cfapiintegrationcloverlyelectricity/src/Cfapiintegrationcloverlyelectricity";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PerformanceTrackerCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerCoursesScreen";
import PerformanceTrackerTestsForCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestsForCourseScreen";
import PerformanceTrackerStudentsOnCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentsOnCourseScreen";
import PerformanceTrackerTestScoresForStudentScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestScoresForStudentScreen";
import PerformanceTrackerBasicUserCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserCoursesScreen";
import PerformanceTrackerBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserAllCoursesScreen";
import PerformanceTrackerStudentRankingScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentRankingScreen";
import PerformanceTrackerStudentInfoScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentInfoScreen";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfapiintegrationwebsitecarbonwebsitecarbon from "../../blocks/cfapiintegrationwebsitecarbonwebsitecarbon/src/Cfapiintegrationwebsitecarbonwebsitecarbon";
import Cfapiintegrationgivvablegetlocationbyid from "../../blocks/cfapiintegrationgivvablegetlocationbyid/src/Cfapiintegrationgivvablegetlocationbyid";
import Multiplelandingpage2 from "../../blocks/multiplelandingpage2/src/Multiplelandingpage2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfapiintegrationcloverlyfuel from "../../blocks/cfapiintegrationcloverlyfuel/src/Cfapiintegrationcloverlyfuel";
import Multipageforms2 from "../../blocks/multipageforms2/src/Multipageforms2";



const routeMap = {
Cfapiintegrationcloverlyvehicle:{
 component:Cfapiintegrationcloverlyvehicle,
path:"/Cfapiintegrationcloverlyvehicle"},
Cfapiintegrationcloverlyhotel:{
 component:Cfapiintegrationcloverlyhotel,
path:"/Cfapiintegrationcloverlyhotel"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Hamburgermenu:{
 component:Hamburgermenu,
path:"/Hamburgermenu"},
Surveys2:{
 component:Surveys2,
path:"/Surveys2"},
Cfapiintegrationtrycarbonfueltoco2e2:{
 component:Cfapiintegrationtrycarbonfueltoco2e2,
path:"/Cfapiintegrationtrycarbonfueltoco2e2"},
Cfapiintegrationcloverlybycarbonamount:{
 component:Cfapiintegrationcloverlybycarbonamount,
path:"/Cfapiintegrationcloverlybycarbonamount"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Cfapiintegrationtrycarboncleanenergy2:{
 component:Cfapiintegrationtrycarboncleanenergy2,
path:"/Cfapiintegrationtrycarboncleanenergy2"},
ThemeBlock:{
 component:ThemeBlock,
path:"/ThemeBlock"},
Dynamiccontent2:{
 component:Dynamiccontent2,
path:"/Dynamiccontent2"},
Fullteamvisibility2:{
 component:Fullteamvisibility2,
path:"/Fullteamvisibility2"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Documentstorage:{
 component:Documentstorage,
path:"/Documentstorage"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Interactivecalculator2:{
 component:Interactivecalculator2,
path:"/Interactivecalculator2"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Cfapiintegrationcloverlypackageshipping:{
 component:Cfapiintegrationcloverlypackageshipping,
path:"/Cfapiintegrationcloverlypackageshipping"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Productrecommendationengine:{
 component:Productrecommendationengine,
path:"/Productrecommendationengine"},
Cfapiintegrationgivvablegetorganisations:{
 component:Cfapiintegrationgivvablegetorganisations,
path:"/Cfapiintegrationgivvablegetorganisations"},
Download:{
 component:Download,
path:"/Download"},
Merchantdashboard2:{
 component:Merchantdashboard2,
path:"/Merchantdashboard2"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Cfapiintegrationtrycarbonpublictransit:{
 component:Cfapiintegrationtrycarbonpublictransit,
path:"/Cfapiintegrationtrycarbonpublictransit"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Blogpostsmanagement:{
 component:Blogpostsmanagement,
path:"/Blogpostsmanagement"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Cfapiintegrationgivvablegetcategories:{
 component:Cfapiintegrationgivvablegetcategories,
path:"/Cfapiintegrationgivvablegetcategories"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Cfapiintegrationcloverlyfreight:{
 component:Cfapiintegrationcloverlyfreight,
path:"/Cfapiintegrationcloverlyfreight"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Cfapiintegrationgivvablegetevents2:{
 component:Cfapiintegrationgivvablegetevents2,
path:"/Cfapiintegrationgivvablegetevents2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Cfblogapiintegration2:{
 component:Cfblogapiintegration2,
path:"/Cfblogapiintegration2"},
Chatbot:{
 component:Chatbot,
path:"/Chatbot"},
Projectreporting:{
 component:Projectreporting,
path:"/Projectreporting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Cfapiintegrationtrycarbonflight:{
 component:Cfapiintegrationtrycarbonflight,
path:"/Cfapiintegrationtrycarbonflight"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Goalmanagement:{
 component:Goalmanagement,
path:"/Goalmanagement"},
Cfapiintegrationtrycarboncartravel2:{
 component:Cfapiintegrationtrycarboncartravel2,
path:"/Cfapiintegrationtrycarboncartravel2"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
UserProfileAdvance:{
 component:UserProfileAdvance,
path:"/UserProfileAdvance"},
Cfapiintegrationcloverlyflight:{
 component:Cfapiintegrationcloverlyflight,
path:"/Cfapiintegrationcloverlyflight"},
Cfesgmaterialitymatrixmanagement3:{
 component:Cfesgmaterialitymatrixmanagement3,
path:"/Cfesgmaterialitymatrixmanagement3"},
Cfapiintegrationgivvablegetidentifiertypebyid:{
 component:Cfapiintegrationgivvablegetidentifiertypebyid,
path:"/Cfapiintegrationgivvablegetidentifiertypebyid"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Cfapiintegrationcloverlyelectricity:{
 component:Cfapiintegrationcloverlyelectricity,
path:"/Cfapiintegrationcloverlyelectricity"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PerformanceTrackerCoursesScreen:{
 component:PerformanceTrackerCoursesScreen,
path:"/PerformanceTrackerCoursesScreen"},
PerformanceTrackerTestsForCourseScreen:{
 component:PerformanceTrackerTestsForCourseScreen,
path:"/PerformanceTrackerTestsForCourseScreen"},
PerformanceTrackerStudentsOnCourseScreen:{
 component:PerformanceTrackerStudentsOnCourseScreen,
path:"/PerformanceTrackerStudentsOnCourseScreen"},
PerformanceTrackerTestScoresForStudentScreen:{
 component:PerformanceTrackerTestScoresForStudentScreen,
path:"/PerformanceTrackerTestScoresForStudentScreen"},
PerformanceTrackerBasicUserCoursesScreen:{
 component:PerformanceTrackerBasicUserCoursesScreen,
path:"/PerformanceTrackerBasicUserCoursesScreen"},
PerformanceTrackerBasicUserAllCoursesScreen:{
 component:PerformanceTrackerBasicUserAllCoursesScreen,
path:"/PerformanceTrackerBasicUserAllCoursesScreen"},
PerformanceTrackerStudentRankingScreen:{
 component:PerformanceTrackerStudentRankingScreen,
path:"/PerformanceTrackerStudentRankingScreen"},
PerformanceTrackerStudentInfoScreen:{
 component:PerformanceTrackerStudentInfoScreen,
path:"/PerformanceTrackerStudentInfoScreen"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfapiintegrationwebsitecarbonwebsitecarbon:{
 component:Cfapiintegrationwebsitecarbonwebsitecarbon,
path:"/Cfapiintegrationwebsitecarbonwebsitecarbon"},
Cfapiintegrationgivvablegetlocationbyid:{
 component:Cfapiintegrationgivvablegetlocationbyid,
path:"/Cfapiintegrationgivvablegetlocationbyid"},
Multiplelandingpage2:{
 component:Multiplelandingpage2,
path:"/Multiplelandingpage2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfapiintegrationcloverlyfuel:{
 component:Cfapiintegrationcloverlyfuel,
path:"/Cfapiintegrationcloverlyfuel"},
Multipageforms2:{
 component:Multipageforms2,
path:"/Multipageforms2"},

  Home: {
component:BulkUploading,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
