Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfapiintegrationgivvablegetlocationbyid";
exports.labelBodyText = "cfapiintegrationgivvablegetlocationbyid Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End